import { useEffect } from "react";
import { useState } from "react";
import PhoneInput from 'react-phone-number-input'


export const Form = ({ step, onNextStep, formData, onPreviousStep }) => {

    const EMAIL_REGEX = /\S+@\S+\.\S+/;

    const [error, setError] = useState('');

    const [compName, setCompName] = useState(formData.compName);
    const [fName, setFName] = useState(formData.fName);
    const [title, setTitle] = useState(formData.title);
    const [email, setEmail] = useState(formData.email);
    const [phone, setPhone] = useState(formData.phone);
    const [website, setWebsite] = useState(formData.website);

    const [findUs, setFindUs] = useState(formData.findUs);
    const [referral, setReferral] = useState(formData.referral);
    const [condition, setCondition] = useState(formData.condition);
    const [gAnalytics, setGAnalytics] = useState(formData.gAnalytics);
    const [revenue, setRevenue] = useState(formData.revenue);

    const [nature, setNature] = useState(formData.nature);

    const checkEmptyString = (str) => {
        return str && str !== '';
    }

    /**
     * This method is used to validate the values given in the current step
     * 
     * @returns `true`    if the step is valid
     *          `false`   if the step is missing value
     *          `string`  if the step is having some other error
     */
    const validatedStep = () => {
        switch (step) {
            case 1: return checkEmptyString(compName);
            case 2: return checkEmptyString(fName);
            case 3: return checkEmptyString(title);
            case 4: 
                if (!checkEmptyString(email)) {
                    return false;
                }
                if (!EMAIL_REGEX.test(email)) {
                    return 'Invalid Email';
                }
                return true;
            case 5: return checkEmptyString(phone);
            case 6:
                if (!checkEmptyString(findUs)) {
                    return false
                } else if (findUs === 'ref' && !checkEmptyString(referral)) {
                    return false;
                } return true
            case 7: return checkEmptyString(condition);
            case 8: return checkEmptyString(website);
            case 9: return gAnalytics === "true" || gAnalytics === "false";
            case 10:
                if (!nature.crowdfunding && !nature.leadGen && !nature.mobileApp && !nature.eCommerce && !checkEmptyString(nature.other)) {
                    return false;
                } if (nature.other !== null && nature.other === '') {
                    return false;
                } return true;

            case 11: return checkEmptyString(revenue);
            default: return false;
        }
    }

    useEffect(() => {
        setError('');
    }, [compName, fName, title, email, phone, website, findUs, referral, condition, gAnalytics, revenue, nature])

    const onNextButtonClick = () => {
        const isValid = validatedStep();
        
        if(typeof isValid === 'string' && isValid !== '') {
            setError(isValid);
            return;
        }

        if (!isValid) {
            setError('Please fill the field');
            return;
        }

        switch (step) {
            case 1: onNextStep({ compName }); break;
            case 2: onNextStep({ fName }); break;
            case 3: onNextStep({ title }); break;
            case 4: onNextStep({ email }); break;
            case 5: onNextStep({ phone }); break;
            case 6: onNextStep({ findUs, referral }); break;
            case 7: onNextStep({ condition }); break;
            case 8: onNextStep({ website }); break;
            case 9: onNextStep({ gAnalytics }); break;
            case 10: onNextStep({ nature }); break;
            case 11: onNextStep({ revenue }); break;
            default: break;
        }
    }

    const onBackButtonClick = (e) => {
        e.preventDefault();
        onPreviousStep();
    }

    const onKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onNextButtonClick();
        }
    }

    return <>
        <div className="app-form" onKeyDown={onKeyDown}>
            <div className={error === '' ? "form-content" : 'form-content error'}>
                {step === 1 && <>
                    <label className="bold" htmlFor="company">1. Company: </label>
                    <input autoFocus type="text" name="company" id="comp" className="form-input" placeholder="Type here..." value={compName} onChange={e => setCompName(e.target.value)} />
                </>}
                {step === 2 && <>
                    <label className="bold" htmlFor="fName">2. Full Name </label>
                    <input autoFocus type="text" name="fName" id="fName" className="form-input" placeholder="Type here..." value={fName} onChange={e => setFName(e.target.value)} />
                </>}
                {step === 3 && <>
                    <label className="bold" htmlFor="title">3. Title: </label>
                    <input autoFocus type="text" name="title" id="title" className="form-input" placeholder="Type here..." value={title} onChange={e => setTitle(e.target.value)} />
                </>}
                {step === 4 && <>
                    <label className="bold" htmlFor="email">4. Email </label>
                    <input autoFocus type="email" name="email" id="email" className="form-input" placeholder="Type here..." value={email} onChange={e => setEmail(e.target.value)} />
                </>}
                {step === 5 && <>
                    <label className="bold" htmlFor="phone">5. Phone Number: </label>
                    <PhoneInput
                        value={phone}
                        autoFocus
                        defaultCountry="US"
                        onChange={(p) => { setPhone(p) }}
                        placeholder="Type Here..."
                    />
                </>}
                {step === 6 && <>
                    <label className="bold" htmlFor="findUs">6. How did you find us? </label>

                    <div className="inline-radio">
                        <input autoFocus type="radio" id="search" name="findUs" defaultChecked={findUs === 'search'} value="search" onClick={(e) => setFindUs(e.target.value)} /> <label htmlFor="search"> Search</label>
                    </div>

                    <div className="inline-radio">
                        <input type="radio" id="sm" name="findUs" defaultChecked={findUs === 'sm'} value="sm" onClick={(e) => setFindUs(e.target.value)} /> <label htmlFor="sm"> Social Media</label>
                    </div>

                    <div className="inline-radio">
                        <input type="radio" id="ref" name="findUs" defaultChecked={findUs === 'ref'} value="ref" onClick={(e) => {
                            setFindUs(e.target.value)
                        }} /> <label htmlFor="ref"> Referral</label>
                    </div>

                    {findUs === 'ref' &&
                        <input autoFocus type="text" name="ref" id="ref" className="form-input" placeholder="Type here..." value={referral} onChange={e => setReferral(e.target.value)} />
                    }

                </>}

                {step === 7 && <>
                    <label className="bold" htmlFor="phone">7. How would you describe your website? </label>

                    <div className="inline-radio">
                        <input autoFocus type="radio" id="great" name="webCondition" defaultChecked={condition === 'great'} value="great" onClick={(e) => setCondition(e.target.value)} /> <label htmlFor="great"><span className="bold">Great! </span> It meets all our needs</label> 
                    </div>

                    <div className="inline-radio">
                        <input type="radio" id="okay" name="webCondition" defaultChecked={condition === 'okay'} value="okay" onClick={(e) => setCondition(e.target.value)} /> <label htmlFor="okay"><span className="bold">Okay.</span> It could use some work</label> 
                    </div>

                    <div className="inline-radio">
                        <input type="radio" id="bad" name="webCondition" defaultChecked={condition === 'bad'} value="bad" onClick={(e) => setCondition(e.target.value)} /> <label htmlFor="bad"> <span className="bold">Not Good.</span> It isn't useful to our business</label>
                    </div>

                    <div className="inline-radio">
                        <input type="radio" id="none" name="webCondition" defaultChecked={condition === 'none'} value="none" onClick={(e) => setCondition(e.target.value)} /> <label htmlFor="none"> <span className="bold">We Don't Have One. </span> Nothing at all </label>
                    </div>
                </>}

                {step === 8 && <>
                    <label className="bold" htmlFor="website">8. Can we have a link to your website?  </label>
                    <input autoFocus type='url' name="website" id="website" className="form-input" placeholder="Type here..." value={website} onChange={e => setWebsite(e.target.value)} />
                </>}

                {step === 9 && <>
                    <label className="bold" htmlFor="gAnalytics">9. Are you currently integrated with Google Analytics or GA4? </label>

                    <div className="inline-radio">
                        <input autoFocus type="radio" id='gaYes' name="gAnalytics" defaultChecked={gAnalytics === "true"} value="true" onClick={(e) => setGAnalytics(e.target.value)} /> <label htmlFor="gaYes">Yes</label>
                    </div>

                    <div className="inline-radio">
                        <input type="radio" id="gaNo" name="gAnalytics" defaultChecked={gAnalytics === "false"} value="false" onClick={(e) => setGAnalytics(e.target.value)} /> <label htmlFor="gaNo">No</label>
                    </div>

                </>}

                {step === 10 && <>
                    <label className="bold" htmlFor="phone">10. How would you describe the nature of your online advertising? </label>

                    <div className="inline-checkbox">
                        <input autoFocus type="checkbox" id='nature-cf' name="nature" defaultChecked={nature.crowdfunding} value='crowdfunding' onClick={(e) => setNature(n => { return { ...n, [e.target.value]: e.target.checked } })} /> <label htmlFor="nature-cf"><span><span className="bold">Crowdfunding: </span> Funding & Equity Investors</span></label>
                    </div>

                    <div className="inline-checkbox">
                        <input type="checkbox" id="nature-lg" name="nature" defaultChecked={nature.leadGen} value='leadGen' onClick={(e) => setNature(n => { return { ...n, [e.target.value]: e.target.checked } })} /> <label htmlFor="nature-lg"><span><span className="bold">Lead Generation: </span> Sales Leads, Calls, & Form Submissions</span></label> 
                    </div>

                    <div className="inline-checkbox">
                        <input type="checkbox" id="nature-ma" name="nature" defaultChecked={nature.mobileApp} value='mobileApp' onClick={(e) => setNature(n => { return { ...n, [e.target.value]: e.target.checked } })} /> <label htmlFor="nature-ma"> <span><span className="bold">Mobile App: </span> New Downloads & User Revenue</span></label>
                    </div>

                    <div className="inline-checkbox">
                        <input type="checkbox" id="nature-ec" name="nature" defaultChecked={nature.eCommerce} value='eCommerce' onClick={(e) => setNature(n => { return { ...n, [e.target.value]: e.target.checked } })} /> <label htmlFor="nature-ec"><span className="bold">E-commerce: </span> Online Store Customers</label>
                    </div>

                    <div className="inline-checkbox">
                        <input type="checkbox" id="nature-ot" name="nature" defaultChecked={nature.other != null} onClick={(e) => {
                            if (!e.target.checked) {
                                setNature(n => { return { ...n, other: null } })
                            } else {
                                setNature(n => { return { ...n, other: '' } })
                            }
                        }} /> <span className="bold">Other</span>
                    </div>

                    {nature.other !== null &&
                        <input autoFocus type="text" name="ref" id="ref" className="form-input" placeholder="Type here..." value={nature.other} onChange={e => setNature(n => { return { ...n, other: e.target.value } })} />
                    }

                </>}
                {step === 11 && <>
                    <label className="bold" htmlFor="revenue">11. How much revenue are you generating from online advertising?</label>
                    <div className="inline-radio">
                        <input autoFocus type="radio" id='rev50K' name="revenue" defaultChecked={revenue === '50K'} value='50K' onClick={(e) => setRevenue(e.target.value)} /> <label htmlFor="rev50K"> $50K & under</label>
                    </div>

                    <div className="inline-radio">
                        <input type="radio" id="rev500K" name="revenue" defaultChecked={revenue === '500K'} value='500K' onClick={(e) => setRevenue(e.target.value)} /> <label htmlFor="rev500K"> $50K - $500K</label>
                    </div>

                    <div className="inline-radio">
                        <input type="radio" id="rev5M" name="revenue" defaultChecked={revenue === '5M'} value='5M' onClick={(e) => setRevenue(e.target.value)} /> <label htmlFor="rev5M"> $500K - $5MM</label>
                    </div>

                    <div className="inline-radio">
                        <input type="radio" id="rev5M+" name="revenue" defaultChecked={revenue === '5M+'} value='5M+' onClick={(e) => setRevenue(e.target.value)} /> <label htmlFor="rev5M+"> $5MM & up</label>
                    </div>

                    <div className="inline-radio">
                        <input type="radio" id="revTough" name="revenue" defaultChecked={revenue === 'tough'} value='tough' onClick={(e) => setRevenue(e.target.value)} /> <label htmlFor="revTough">Tough to quantify</label> 
                    </div>
                </>}
                <p className="error-text">{error}</p>

                <div className="buttons-div">
                    { step > 1 && <button className="back-button" onClick={onBackButtonClick}>Back</button> }
                    <button className="next-button" onClick={onNextButtonClick}>Next</button>
                </div>
            </div>

        </div>
    </>
}


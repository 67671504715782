import { useState } from "react";
import "react-phone-number-input/style.css";
import "./fonts/fonts.css";
import "./App.scss";
import { Form } from "./components/Form";
import { ProgressBar } from "./components/ProgressBar";

const initialValues = {
    compName: "",
    fName: "",
    title: "",
    email: "",
    phone: "",
    website: "",
    findUs: "",
    referral: "",
    condition: "",
    gAnalytics: "",
    revenue: "",
    nature: {
        crowdfunding: false,
        leadGen: false,
        mobileApp: false,
        eCommerce: false,
        other: null,
    },
};

const findUsLabels = {
    search: "Search",
    sm: "Social Media",
    ref: "Referral",
};

const conditionLabels = {
    great: "Great! It meets all our needs",
    okay: "Okay. It could use some work",
    bad: "Not Good. It isn't useful to our business",
    none: "We Don't Have One. Nothing at all ",
};

const revenueLabels = {
    rev50K: "$50K & under",
    rev500K: "$50K - $500K",
    rev5M: "$500K - $5MM",
    "rev5M+": "$5MM & up",
    revTough: "Tough to quantify",
};

const TOTAL_STEPS = 11;

function App() {
    const [step, setStep] = useState(1);
    const [progress, setProgress] = useState(0);
    const [formData, setFormData] = useState(initialValues);

    const sendEmail = async () => {
        const data = { ...formData };

        data.condition = conditionLabels[data.condition];
        data.findUs = findUsLabels[data.findUs];
        data.revenue = revenueLabels[data.revenue];

        const URL = "https://dbt-development-325613.uc.r.appspot.com/api/v1/email/calculator";
        const response = await fetch(URL, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        return response.status === 200;
    };

    const onNextStep = async (data) => {

        setFormData((fd) => {
            return { ...fd, ...data };
        });

        if (step === TOTAL_STEPS) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
             'event': 'questionnaire_complete',
             'company': formData.compName,
             'full_name': formData.fName,
             'title': formData.title,
             'email_address': formData.email,
             'phone_number': formData.phone,
             'found_us_via': formData.findUs,
             'website': formData.website,
             'analytics_integrated': formData.gAnalytics,
             'online_advertising': formData.nature,
             'how_much_revenue': data.revenue
           });
            sendEmail();
            // const { email } = formData;
            window.top.location.href = `https://www.revenueroll.com/calculator`;
        } else {
            setProgress((step / TOTAL_STEPS) * 100);
            setStep((s) => s + 1);
        }
    };

    const onPreviousStep = () => {
        setStep((s) => s - 1);
    };

    return (
        <div className="App">
            <div className="app-container">
                <div className="app-content">
                    <h1 className="form-heading">Tell Us About Your Business</h1>
                    <ProgressBar completed={progress} />
                    <Form
                        step={step}
                        onNextStep={onNextStep}
                        onPreviousStep={onPreviousStep}
                        formData={formData}
                    />
                </div>
            </div>
        </div>
    );
}

export default App;

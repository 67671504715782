export const ProgressBar = ({ completed }) => {

    return (
        <div className="progress-bar">
            <div className="progress-container">
                <div className="progress-filler" style={{ width: `${completed}%` }}>
                </div>
            </div>
        </div>
    );
};
